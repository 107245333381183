import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-692895d0")
const _hoisted_1 = { class: "toolbar__left" }
const _hoisted_2 = { class: "date_range" }
const _hoisted_3 = { class: "date_range__overlay" }
const _hoisted_4 = { class: "p-text-center" }
const _hoisted_5 = { class: "p-d-flex p-jc-center p-my-2" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    class: "p-mb-3",
    header: "Filters",
    toggleable: true,
    collapsed: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            icon: "pi pi-calendar",
            onClick: _ctx.openCalendar
          }, null, 8, ["onClick"]),
          _createElementVNode("span", {
            class: "date_range__text",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openCalendar && _ctx.openCalendar(...args)))
          }, " From: " + _toDisplayString(_ctx.fromDate) + " To: " + _toDisplayString(_ctx.toDate), 1),
          _createVNode(_component_OverlayPanel, {
            ref: "op",
            appendTo: "body"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("header", _hoisted_4, [
                  _createElementVNode("h5", null, "From: " + _toDisplayString(_ctx.fromDate) + " To: " + _toDisplayString(_ctx.toDate), 1)
                ]),
                _createVNode(_component_Calendar, {
                  modelValue: _ctx.advFilters.from_to,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.advFilters.from_to = $event)),
                  onDateSelect: _ctx.emitFilters,
                  selectionMode: "range",
                  showSeconds: false,
                  inline: true,
                  numberOfMonths: 2
                }, null, 8, ["modelValue", "onDateSelect"]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Button, {
                    label: "Reset",
                    icon: "pi pi-times",
                    class: "p-button-info",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.advFilters.from_to = null))
                  }),
                  _createVNode(_component_Button, {
                    label: "Ok",
                    icon: "pi pi-check",
                    class: "p-ml-3",
                    onClick: _ctx.hideCalendar
                  }, null, 8, ["onClick"])
                ])
              ])
            ]),
            _: 1
          }, 512)
        ])
      ])
    ]),
    _: 1
  }))
}