
  import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
  import { plainToClass } from "class-transformer";
  import moment from "moment";
  import { Options, Vue } from "vue-class-component";
  import { Model } from "vue-property-decorator";
  import { ShiftFilters } from "./ShiftFilters";

  @Options({})
  export default class ShiftsFilters extends Vue {
    @Model("filters")
    advFilters: ShiftFilters;

    openCalendar(event) {
      (this.$refs as any).op?.toggle(event);
    }

    hideCalendar() {
      (this.$refs as any).op?.hide();
    }

    get fromDate() {
      const from = this.advFilters?.from_to?.[0];

      return from ? moment(from).format("YYYY/MM/DD") : "----/--/--";
    }

    get toDate() {
      const to = this.advFilters?.from_to?.[1];

      return to ? moment(to).format("YYYY/MM/DD") : "----/--/--";
    }

    emitFilters() {
      this.saveAdvancedFilters();
      this.$emit("change", this.advFilters);
    }

    private saveAdvancedFilters() {
      sessionStorage.setItem(
        SessionStorageEnum.SHIFTS_ADVANCED_FILTERS,
        JSON.stringify(this.advFilters)
      );
    }

    private restoreAdvancedFilters() {
      const fromStorage = JSON.parse(
        sessionStorage.getItem(SessionStorageEnum.SHIFTS_ADVANCED_FILTERS)
      );

      this.advFilters = fromStorage
        ? plainToClass(ShiftFilters, fromStorage)
        : this.advFilters;
    }

    created() {
      this.restoreAdvancedFilters();
    }
  }
