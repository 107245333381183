import {Options, Vue} from "vue-class-component";
import {Prop, Provide} from "vue-property-decorator";
import {classToPlain, plainToClass} from "class-transformer";

import {SendMessageDialog, ShiftDeleteDialog, W3Autocomplete, W3Sidebar, ZoneAutocomplete} from "@components";

import {Shift, ShiftStatusEnum} from "@/model/api/Shift";
import {shiftsService} from "@services/shifts.service";
import {fileDownload} from "@/utils/utils";
import {ShiftsRoutesEnum} from "../../router";
import {customShiftsService} from "@services/custom-shifts.service";
import {AppShiftStatusEnum} from "@/model/enums/AppShiftStatusEnum";
import {whitelistUsersService} from "@services/whitelist_users.service";
import {User} from "@/model/api/User";
import moment from "moment";
import {PaymentTypeEnum} from "@/model/enums/PaymentTypeEnum";
import {authStore} from "@/modules/auth/store";
import {AccessesBySection, UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {configuration} from "@plugins/Config-plugin";
import {authService} from "@services/auth.service";

@Options({
  components: {
    SendMessageDialog,
    ShiftDeleteDialog,
    W3Autocomplete,
    W3Sidebar,
    ZoneAutocomplete
  },
  beforeRouteLeave() {
    whitelistUsersService.clearUsers()
  }
})
export default class ShiftPage extends Vue {

  displayMessageDialog: boolean = false;
  displayDeleteDialog: boolean = false;

  message: string = null;
  subject: string = null;
  sendToAll: boolean = false;

  @Prop()
  @Provide({ reactive: true })
  readonly shiftId!: String;

  shift: Shift = new Shift();

  @Provide({ reactive: true })
  oldStatus: ShiftStatusEnum = null;

  @Provide({ reactive: true })
  get isReadOnly() {
    const me = authStore.getters.me
    return !AccessesBySection.WRS_EDIT.includes(me.role) || this.isDeleted || (me.role == UserRoleEnum.CUSTOMER && this.shift.customer_id != me.customer_id);
  }

  @Provide({ reactive: true })
  get isDeleted() {
    return this.shift.status === ShiftStatusEnum.DISABLED && this.oldStatus == ShiftStatusEnum.DISABLED
  }


  @Provide({ reactive: true })
  get hasTickets() {
    return this.shift?.has_tickets;
  }

  onMessageDialogChange() {
    if (this.$route.query.cloned) {
      window.location.href = window.location.origin + window.location.pathname;
    }
  }

  resetMessage() {
    this.sendToAll = false;
    this.message = null;
    // this.onMessageDialogChange()
  }

  onDeleted() {
    this.$router.replace({
      name: ShiftsRoutesEnum.SHIFTS_LIST
    });
  }

  get actions() {
    const actions = [
      {
        label: 'Export',
        icon: 'pi pi-file-excel',
        command: () => {
          this.export();
        }
      }
    ];

    if(
        this.shift.status &&
        this.shift.status !== ShiftStatusEnum.TO_BE_APPROVED &&
        this.canPerformActions
    ){
      actions.push(  {
        label: 'Send message',
        icon: 'pi pi-send',
        command: () => {
          this.displayMessageDialog = true;
        }
      });
    }

    if (!this.isNew) {
      actions.push({
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => {
          this.displayDeleteDialog = true;
        }
      });
    }

    return actions;
  }

  get tabs() {
    let i = 0;

    const tabs = [
      {
        label: this.$t('shift.tabs.data'),
        icon: 'fas fa-road',
        to: { name: ShiftsRoutesEnum.SHIFTS_DETAIL },
        class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
      }
    ];


    if(authStore.getters.me.role !== UserRoleEnum.BROKER){
      tabs.push({
          label: this.$t('shift.tabs.ticket_list'),
          icon: 'fas fa-ticket-alt',
          to: { name: ShiftsRoutesEnum.SHIFTS_DETAIL_TICKETS },
          class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
        }, {
          label: this.$t('shift.tabs.tickets_history'),
          icon: 'fas fa-road',
          to: { name: ShiftsRoutesEnum.SHIFTS_TICKETS_HISTORY },
          class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
        }, {
          label: this.$t('shift.tabs.kpi'),
          icon: 'fas fa-chart-pie',
          to: { name: ShiftsRoutesEnum.SHIFTS_KPI },
          class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
        })
    }

    return tabs

  }


  get isNew() {
    return this.shiftId === 'new';
  }

  get backRoute() {
    return { name: ShiftsRoutesEnum.SHIFTS_LIST }
  }

  private async export() {
    try {
      const response = await shiftsService.exportExcel();

      const fileName = "//TODO_FILE_NAME";

      fileDownload(
        fileName,
        response,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    } catch (error) {
      this.$errorMessage("Export failed");
    }
  }

  async addUsersToNewShift(broker: User, shift_id: number) {
    return await whitelistUsersService.addUser({ shift_id, broker })
  }

  mounted() {
    if (this.$route.query.cloned && this.canPerformActions) {
      this.displayMessageDialog = true;
    }
  }

  updateDates(res){
    ['shift_datetime', 'start_datetime', 'end_datetime', 'expire_at', 'broker_status_at', 'shift_day'].forEach((key) => {
      if(res[key] && res[key] instanceof Date){
        const utcOffset = moment(res[key]).tz(res.zone_name).utcOffset();
        const newDate = moment(res[key].setHours(res[key].getHours() + (-(utcOffset / 60))))
        if(key !== 'shift_day'){
          res[key] = newDate.format('YYYY-MM-DDTHH:mm:ss.000000\\Z')
        } else if(res[key] instanceof Date){
          res[key] = newDate.format('YYYY-MM-DD')
        }
      }
    })
  }

  get canPerformActions() {
    return [
      UserRoleEnum.SUPER_ADMIN,
      UserRoleEnum.CUSTOMER,
      UserRoleEnum.CLIENT
    ].includes(authStore.getters.me.role);
  }

  onSave() {

    const actualSaving = () => {

      if (this.shift.customer) {
        this.shift.customer_id = this.shift.customer.id
      }

      if (this.shift.foreman) {
        this.shift.foreman_id = this.shift.foreman.id
      }

      // @ts-ignore
      if (this.shift.expire_at && (this.shift.expire_at -  this.shift.end_datetime < 0)) {
        this.$errorMessage("Expiration must be after end datetime");
        return
      }

      // clean oppositve fields
      switch (this.shift.payment_type) {
        case PaymentTypeEnum.CYCLE:
          this.shift.estimated_ton = 0;
          break;
        case PaymentTypeEnum.TON:
          this.shift.cycles = 0;
          this.shift.is_ton_requested = false;
          break;
        default:
          this.shift.cycles = 0;
          this.shift.estimated_ton = 0;
          break;
      }

      this.$waitFor(
        async () => {
          if (this.isNew) {
            const plainShift = classToPlain(this.shift);
            this.updateDates(plainShift)
            if(!this.shift.trucks_required_indipendent){
              plainShift.trucks_required = 0
            }
            const res = await shiftsService.create(plainShift as any)

            if (plainShift.custom_shift_id) {
              await customShiftsService.updatePatch({
                id: plainShift.custom_shift_id,
                status: AppShiftStatusEnum.APPROVED
              } as any)
            }

            const cachedWhitelistUsers = [...await whitelistUsersService.getUsers()]
            let sumTruckRequired = 0
            cachedWhitelistUsers.map((broker: User) => {
              sumTruckRequired += broker.trucks_required
              return this.addUsersToNewShift(broker, res.id)
            })

            this.shift = plainToClass(Shift, res)
            if(!this.shift.trucks_required_indipendent){
              this.shift.trucks_required = sumTruckRequired
            }
            this.shift.updateDates()

            await Promise.all(cachedWhitelistUsers);

            await this.$router.replace({
              name: ShiftsRoutesEnum.SHIFTS_DETAIL,
              params: {
                shiftId: res.id
              }
            });
            if (plainShift.custom_shift_id) {
              this.$successMessage(this.$t("app_shift.messages.approve_success"));
            }
            this.$successMessage("Shift successfully created");
            if(this.canPerformActions){
              this.subject = "New Shift on " + configuration.appTitle;
              this.message =  "Check \"New Shift Requests\" in your app to view and accept";
              this.sendToAll = true;
              this.displayMessageDialog = true;
            }

          } else {
            const plainShift = classToPlain(this.shift);
            this.updateDates(plainShift)
            const response = await shiftsService.updatePatch(plainShift as any);
            this.shift = plainToClass(Shift, response);
            this.shift.updateDates()
            this.$successMessage("Shift successfully updated");
          }
          this.oldStatus = this.shift.status;
        },
        "Saving failed"
      )
    }

    if (!whitelistUsersService.whitelistedUsers.length) {
      this.$confirmMessage("No broker selected. This WRK will not be visible to any driver. Are you sure?")
        .then(r => {
          if (r) {
            actualSaving();
          }
        })
    } else {
      actualSaving();
    }

  }

  async onDelete() {
    this.displayDeleteDialog = true;
  }

  private async _loadShift() {
    return this.$waitFor(
      async () => {
        this.shift = await shiftsService.getById(+this.shiftId);
        this.oldStatus = this.shift.status
      }
    )
  }

  private async _loadCustomShift(id: number) {
    this.$waitFor(
      async () => {
        this.shift = await customShiftsService.getShift(id);
        this.shift.custom_shift_id = id
      }
    )
  }

  async created() {
     whitelistUsersService.clearUsers();
    if (this.isNew) {
      if (this.$route.query.custom_shift_id) {
        await this._loadCustomShift(+this.$route.query.custom_shift_id)
      } else if(this.$route.query.activityId){
        this.shift = await shiftsService.prepareByActivityId(+this.$route.query.activityId);
      } else {
        this.shift = new Shift();
        if (authStore.getters.me.role == UserRoleEnum.CUSTOMER) {
          const meCust = authStore.getters.me.customer
          this.shift.customer = meCust
          this.shift.customer_id = meCust.id
        }
      }
    } else {
      await this._loadShift();
    }
  }
}
