export enum AppShiftStatusEnum {
    PENDING   = 0,
    APPROVED  = 10,
    REJECTED  = 20
}


export const appShiftStatuses = [
    AppShiftStatusEnum.PENDING,
    AppShiftStatusEnum.APPROVED,
    AppShiftStatusEnum.REJECTED,
];