import {Options, Vue} from "vue-class-component";
import {Inject, Prop, Watch} from "vue-property-decorator";
import {Shift} from "@/model/api/Shift";
import {shiftsService} from "@services/shifts.service";
import {ticketsService} from "@services/tickets.service";
import {Ticket} from "@/model/api/Ticket";
import {TicketStatusEnum} from "@/model/enums/TicketStatusEnum";
import {authStore} from "@/modules/auth/store";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {PaymentTypeEnum} from "@/model/enums/PaymentTypeEnum";
import {ShiftKPIDialog} from "@components";
import {KPIMessageTypes} from "@/model/enums/KPIMessageTypes";
import {whitelistUsersService} from "@services/whitelist_users.service";

@Options({
    inheritAttrs: false,
    components: {
        ShiftKPIDialog
    }
})
export default class ShiftKPI extends Vue {

    @Inject()
    readonly shiftId!: string;

    @Prop() readonly shift!: Shift;
    @Watch('shift')
    private async onLoadedShift(shift) {
        if(shift && !this.shiftLoaded){
            await this._loadGraphs();
        }
    }

    private shiftLoaded: boolean = false
    public waitingLoadCount: number = 0
    public waitingDumpCount: number = 0
    public dumpedCount: number = 0
    public loadedCount: number = 0
    public fee_client: number | string = 0
    public totalTrucks: number = 0
    public timePerCycle: string | number = 0
    public totalCycles: number = 0
    public totalTon: number = 0
    public hours: string = ''
    public displayKPIDialog: boolean = false
    public kpiModalAction: KPIMessageTypes = KPIMessageTypes.CANCEL_TRUCKS
    public refreshInterval = null
    public counter = 60

    public avgLoadChart = {
        labels: [],
        datasets: []
    }
    targetLoadTime: number = 0

    public avgDumpChart = {
        labels: [],
        datasets: []
    }
    targetDumpTime: number = 0

    public avgTravelChart = {
        labels: [],
        datasets: []
    }
    targetTravelTime: number = 0

    public avgTravelToDumpChart = {
        labels: [],
        datasets: []
    }
    targetTravelToDumpTime: number = 0

    public avgCycleTimeChart = {
        labels: [],
        datasets: []
    }
    targetCycleTime: number = 0

    public avgCycleChart = {
        labels: [],
        datasets: []
    }
    targetCycle: number = 0

    public avgTonsChart = {
        labels: [],
        datasets: []
    }
    targetTons: number = 0

    public chartOptions = {
        aspectRatio: 1.6,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    }

    get KPIMessageTypes() {
        return KPIMessageTypes
    }

    calculateTickets() {
        this.$waitFor(
            async () => {
                await shiftsService.getShiftAvg(this.shiftId)
                await this._loadGraphs()
            }
        )
    }

    loadTickets(){
        return ticketsService.getAll({
            per_page: 1000,
            filters: {
                "shift_id": {"value": this.shiftId, "matchMode": "equals"}
            }
        })
    }

    get imAdmin() {
        return authStore.getters.me.role === UserRoleEnum.SUPER_ADMIN
    }

    changeKPI(action){
        this.kpiModalAction = action
        this.displayKPIDialog = true
    }

    lifecycleView(tickets: Ticket[]) {

        this.waitingLoadCount = 0;
        this.waitingDumpCount = 0;
        this.dumpedCount = 0;
        this.fee_client = this.shift.payment_type === PaymentTypeEnum.CYCLE ? this.shift.contract?.price_cycle_client : 0;
        this.loadedCount = 0;
        this.totalTrucks = 0;
        let hours: number = 0

        this.timePerCycle = 0
        this.totalCycles = 0
        this.totalTon = 0

        const avg_drive_to_load: number[] | number = []
        const now = new Date()
        tickets.forEach((ticket: Ticket) => {
            if(this.shift.payment_type !== PaymentTypeEnum.CYCLE){
                (this as any).fee_client += ticket.fee_client
            }
            this.totalCycles += ticket.cycles
            this.totalTon += ticket.total_ton

            if(ticket.status >= 100){
                this.totalTrucks++

                if(ticket.status === TicketStatusEnum.DUMPED ||
                    ticket.status === TicketStatusEnum.LOADED ||
                    ticket.status === TicketStatusEnum.WAITING_DUMP ||
                    ticket.status === TicketStatusEnum.WAITING_LOAD
                ){

                    // @ts-ignore
                    const timeDiff = now - new Date(ticket.datetime_start);
                    hours += (timeDiff / (1000 * 60 * 60));

                    switch (ticket.status) {
                        case TicketStatusEnum.WAITING_LOAD:
                            this.waitingLoadCount++
                            break
                        case TicketStatusEnum.WAITING_DUMP:
                            this.waitingDumpCount++
                            break
                        case TicketStatusEnum.DUMPED:
                            this.dumpedCount++
                            break
                        case TicketStatusEnum.LOADED:
                            this.loadedCount++
                            break
                        default:
                            break
                    }
                } else if(ticket.status === TicketStatusEnum.CLOSED ||
                    ticket.status === TicketStatusEnum.DISPUTE
                ){
                    // @ts-ignore
                    const timeDiff = new Date(ticket.datetime_end) - new Date(ticket.datetime_start);
                    if(timeDiff > 0){
                        hours += (timeDiff / (1000 * 60 * 60));
                    }
                }
            }
            this.hours = hours.toFixed(2)
            if(ticket.avg_drive_to_load){
                avg_drive_to_load.push(ticket.avg_drive_to_load / 60)
            }
        })
        if (typeof this.fee_client === "number") {
            this.fee_client = this.fee_client?.toFixed(2)
        } else {
            this.fee_client = "-";
        }
        this.timePerCycle = ((avg_drive_to_load.length ? avg_drive_to_load.reduce((a, b) => a + b, 0) / avg_drive_to_load.length : 0) + this.targetCycleTime).toFixed(2);
    }

    changeTarget($event, chart) {
        this[chart] = {
            labels: this[chart].labels,
            datasets: [
                {
                    type: 'line',
                    label: this[chart].datasets[0].label,
                    borderColor: '#42A5F5',
                    borderWidth: 2,
                    fill: false,
                    data: new Array(this[chart].datasets[1].data.length).fill(parseInt($event.target.value)),
                },
                this[chart].datasets[1]
            ]
        }
    }

    get canCalculate() {
        return authStore.getters.me.role === UserRoleEnum.SUPER_ADMIN || authStore.getters.me.role === UserRoleEnum.CUSTOMER
    }

    private _loadGraphs() {
        return this.$waitFor(
            async () => {

                const charts: any = {}

                const chartsData = {
                    avgLoadChart: {
                        label: 'Load Time',
                        backgroundColor: '#66BB6A',
                        target: 'targetLoadTime',
                        key: 'avg_load'
                    },
                    avgDumpChart: {
                        label: 'Dump Time',
                        backgroundColor: '#42A5F5',
                        target: 'targetDumpTime',
                        key: 'avg_dump'
                    },
                    avgTravelChart: {
                        label: 'Time to Load',
                        backgroundColor: '#FFA726',
                        target: 'targetTravelTime',
                        key: 'avg_drive_to_load'
                    },
                    avgTravelToDumpChart: {
                        label: 'Time to Dump',
                        backgroundColor: '#FF7043',
                        target: 'targetTravelToDumpTime',
                        key: 'avg_drive_to_dump'
                    },
                    avgCycleTimeChart: {
                        label: 'Cycle Time',
                        backgroundColor: '#EF5350',
                        target: 'targetCycleTime',
                        key: 'avg_cycle'
                    },
                    avgCycleChart: {
                        label: 'Cycle',
                        backgroundColor: '#BB9466',
                        target: 'targetCycle',
                        key: 'cycles'
                    },
                    avgTonsChart: {
                        label: 'Tons',
                        backgroundColor: '#BB66B7',
                        target: 'targetTons',
                        key: 'total_ton'
                    }
                }

                for (const chart in chartsData) {
                    charts[chart] = {
                        labels: [],
                        datasets: [
                            {
                                type: 'bar',
                                label: `Average ${chartsData[chart].label}`,
                                backgroundColor: chartsData[chart].backgroundColor,
                                data: [],
                                borderColor: 'white',
                                borderWidth: 2
                            }
                        ]
                    }
                }

                const res: any = await this.loadTickets()

                for (const chart in chartsData) {
                    const targetLoadTime: number[] = []
                    res.data.forEach((ticket: Ticket) => {
                        charts[chart].labels.push(ticket.user.fullname)
                        if (ticket[chartsData[chart].key]) {
                            targetLoadTime.push(ticket[chartsData[chart].key] / 60)
                        }
                        charts[chart].datasets[0].data.push(ticket[chartsData[chart].key] / 60)
                    })
                    this[chartsData[chart].target] = targetLoadTime.length ? targetLoadTime.reduce((a, b) => a + b, 0) / targetLoadTime.length : 0
                    charts[chart].datasets.unshift({
                        type: 'line',
                        label: `Target ${chartsData[chart].label}`,
                        borderColor: '#42A5F5',
                        borderWidth: 2,
                        fill: false,
                        data: new Array(res.data.length).fill(this[chartsData[chart].target])
                    })
                    this[chart] = charts[chart]
                }

                this.lifecycleView(res.data)

            }
        );
    }

    async refresh(){
        this.counter = 60
        await this._loadGraphs()
    }

    handleVisibilityChange(){
        if(document.visibilityState === "visible"){
            this.refreshInterval = setInterval(() => {
                this.counter--
                if(this.counter === 0){
                    this._loadGraphs()
                    this.counter = 60
                }
            }, 1000)
        } else {
            clearTimeout(this.refreshInterval)
        }
    }

    get imForeman(){
        return authStore.getters.me.role === UserRoleEnum.FOREMAN
    }

    async activated() {
        if(this.shift.contract){
            this.shiftLoaded = true
            await this._loadGraphs()
        }
        document.addEventListener("visibilitychange", this.handleVisibilityChange);
        this.handleVisibilityChange()
    }

    deactivated() {
        this.counter = 60
        clearTimeout(this.refreshInterval)
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);

    }

    unmounted(){
        this.counter = 60
        clearTimeout(this.refreshInterval)
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }

}
