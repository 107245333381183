import {Options, Vue} from "vue-class-component";
import {Inject, Prop, Watch} from "vue-property-decorator";
import {Shift} from "@/model/api/Shift";
import {shiftsService} from "@services/shifts.service";
import {TicketStatusEnum} from "@/model/enums/TicketStatusEnum";
import {authStore} from "@/modules/auth/store";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {trackingService} from "@services/traking.service";
import {FilterMatchMode} from "primevue/api";
import {zonesService} from "@services/zones.service";
import {ZoneTypeEnum} from "@/model/enums/ZoneTypeEnum";
import timezone from "moment-timezone";
import {markRaw, toRaw} from "vue";
import {Zone} from "@/model/api/Zone";
import {getDarkColor} from "@/utils/utils";
import {TicketsRoutesEnum} from "@/modules/tickets/router";

const pinSVGHole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
const labelOriginFilled =  new google.maps.Point(12,9);

const markerImage:any = {
    path: pinSVGHole,
    anchor: new google.maps.Point(12,17),
    fillOpacity: 1,
    strokeWeight: 2,
    strokeColor: "white",
    scale: 2,
    labelOrigin: labelOriginFilled
};

@Options({
    inheritAttrs: false,
    components: {},
    setup() {
        const loadShape: google.maps.Polygon = markRaw(null);
        const dumpShape: google.maps.Polygon = markRaw(null);

        return {
            loadShape,
            dumpShape
        }
    }
})
export default class ShiftTicketsHistory extends Vue {

    @Inject()
    readonly shiftId!: string;

    @Prop() readonly shift!: Shift;
    @Watch('shift')
    private async onLoadedShift(shift) {
        if(shift && !this.shiftLoaded){
            await this.init()
        }
    }

    sliderIndex: number = 0;
    wrsColorsMap: any = {};
    private shiftLoaded: boolean = false
    private mergedTracking: any[] = []
    private flatMergedTracking: any[] = []
    public ticketsHistory: any[] = []
    zones: { zone?, dump? } = {}

    @Watch('sliderIndex')
    onSliderIndexChange(value, old) {

        this.toggleTrackingButtons(value, true)
        this.toggleTrackingButtons(old, false)

        this._initMarker();
    }

    mapRef: google.maps.Map = null;

    markers: google.maps.Marker[] = [];

    isVisible(){
        return authStore.getters.me.role !== UserRoleEnum.FOREMAN
    }

    get imAdmin() {
        return authStore.getters.me.role === UserRoleEnum.SUPER_ADMIN
    }

    get maxSlider() {
        return (this.mergedTracking?.length - 1) || 0;
    }

    private toggleTrackingButtons(value, selected){

        if(Array.isArray(this.mergedTracking[value].ticketId)){
            this.mergedTracking[value].ticketId.forEach((id) => {
                const currentTicket = this.ticketsHistory.find((ticket) => ticket.id === id);
                const stepButton = currentTicket.sliderSteps.find((step) => step.index === value)
                if(stepButton){
                    stepButton.selected = selected
                }
            })
        } else {
            const currentTicket = this.ticketsHistory.find((ticket) => ticket.id === this.mergedTracking[value].ticketId);
            const stepButton = currentTicket.sliderSteps.find((step) => step.index === value)
            if(stepButton){
                stepButton.selected = selected
            }
        }

    }

    private async _loadTracking(ticketId) {
        return await trackingService.indexAll({
            filters: {
                ticket_id: {
                    value: ticketId,
                    matchMode: FilterMatchMode.EQUALS
                }
            }
        });
    }

    changeSelectedTickets(ticket){
        if(ticket.selected){
            this.map.data.overrideStyle(toRaw(ticket.geometry), { visible: true });
            toRaw(ticket.marker).setMap(this.map);
        } else {
            this.map.data.overrideStyle(toRaw(ticket.geometry), { visible: false });
            toRaw(ticket.marker).setMap(null);
        }
    }

    isValidStep(status: number): boolean {
        return status === TicketStatusEnum.LOADED || status === TicketStatusEnum.DUMPED
    }

    get timeZone(): string {
        return this.shift?.zone_name;
    }

    changeStep(stepIndex: number) {
        this.sliderIndex = stepIndex
    }

    getStatusLabel(step) {
        if (step.status === TicketStatusEnum.DUMPED && step.is_free_dumped) {
            return this.$t(`ticket.statuses.FREE_DUMPED`)
        }
        return this.$t(`ticket.statuses.${TicketStatusEnum[step.status]}`)
    }

    get TicketStatusEnum() {
        return TicketStatusEnum;
    }

    setSlider(status: number, stepIndex: number) {
        this.sliderIndex = stepIndex;
    }

    private setSteps() {

        this.ticketsHistory.forEach(({id, sliderSteps, tracking, history}) => {
            const sliderWidth = (this.$refs['status_bar_' + id] as HTMLElement).clientWidth

            if (sliderSteps.length) {
                sliderSteps.forEach((step) => {
                    const mt = this.mergedTracking.length / step.index
                    step.position = (sliderWidth / mt).toFixed(2)
                })
            } else if (tracking && tracking.length) {

                let currentStatus: number

                history.forEach((step: any) => {
                    if (this.isValidStep(step.status)) {
                        let diffDate
                        this.mergedTracking.forEach((mt: any, index) => {

                            let trackIndex
                            let mtStatus
                            if(Array.isArray(mt.ticketId)){
                                trackIndex = mt.ticketId.findIndex((tId) => tId === id)
                            }
                            if(trackIndex !== -1){
                                mtStatus = mt.status[trackIndex]
                            } else {
                                mtStatus = mt.status
                            }
                            if(
                                (mt.ticketId === id || trackIndex !== -1) &&
                                (currentStatus !== mtStatus) &&
                                this.isValidStep(mtStatus)
                            ){
                                currentStatus = mt.status
                                const diff: number = Math.abs(new Date(step.created_at).getTime() - new Date(mt.created_at).getTime())
                                if (!diffDate || diff < diffDate[0]) {
                                    diffDate = [diff, index]
                                }
                            }
                        })
                        const date = step.created_at;
                        const mt = this.mergedTracking.length / diffDate[1]
                        sliderSteps.push({
                            status: step.status,
                            is_free_dumped: step.is_free_dumped,
                            id: step.id,
                            index: diffDate[1],
                            time: timezone.tz(date, this.timeZone).format("hh:mm A"),
                            position: (sliderWidth / mt).toFixed(2)
                        })
                    }
                })

            }
        })


    }

    private _loadHistory() {
        return this.$waitFor(
            async () => {
                const ticketsHistory:any = await shiftsService.getTicketsHistory(+this.shiftId)
                this.ticketsHistory = ticketsHistory.tickets

                const promises = this.ticketsHistory.map((ticket) => this._loadTracking(ticket.id))

                const ticketsTracking = await Promise.all(promises)

                const mergedTracking = []

                this.ticketsHistory.forEach((ticket, index)=> {
                    ticket.selected = true
                    ticket.sliderSteps = []
                    for (let i = 0; i < ticketsTracking[index].length; i++) {
                        this.flatMergedTracking.push(ticketsTracking[index][i]);
                    }
                    ticketsTracking[index].forEach((tracking: any) => {
                        const sameDateTracking = mergedTracking.find((mt) => mt.created_at === tracking.created_at);
                        if(!sameDateTracking){
                            mergedTracking.push({
                                ticketId:ticket.id,
                                ...tracking
                            })
                        } else {
                            for (const [key, value] of Object.entries(sameDateTracking)) {
                                if(key !== 'created_at'){
                                    if(Array.isArray(sameDateTracking[key])){
                                        sameDateTracking[key].push(tracking[key] || ticket.id)
                                    } else {
                                        sameDateTracking[key] = [value, tracking[key] || ticket.id]
                                    }
                                }
                            }
                        }
                    })
                    ticket.tracking = ticketsTracking[index]
                });
                this.mergedTracking = mergedTracking.sort((a, b) =>
                    new Date(a['created_at']).valueOf() - new Date(b['created_at']).valueOf()
                );
            }
        );
    }

    private _loadZone(zone, zoneId) {
        return this.$waitFor(async () => {
            this.zones[zone] = await zonesService.getById(+zoneId)
            this.zones[zone].zone_type = ZoneTypeEnum[zone.toUpperCase()]
        })
    }

    get mapElement() {
        return (this.$refs.mapEl as HTMLElement)
    }

    get map() {
        return toRaw(this.mapRef);
    }

    private _drawTracking() {
        const bounds = new google.maps.LatLngBounds();

        this.flatMergedTracking.forEach((x) => {
            bounds.extend(x)
        });

        this.map.fitBounds(bounds);

        this.ticketsHistory.forEach((ticket) => {
            if(ticket.tracking && ticket.tracking.length){
                const geometry  = new google.maps.Data.LineString(
                    ticket.tracking.map(x => ({lat: x.lat, lng: x.lng}))
                )
                ticket.geometry = this.map.data.add({ geometry });
                this.map.data.overrideStyle(toRaw(ticket.geometry), { visible: !!ticket.selected });
            }
        })
    }

    private _initShape(){
        const options: any = {
            strokeColor: "00ffb7",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "00ffb7",
            fillOpacity: 0.35
        };
        (this as any).loadShape = new google.maps.Polygon(options);
        (this as any).dumpShape = new google.maps.Polygon(options);
    }

    private _buildMap(element: HTMLElement, bounds?: google.maps.LatLngBounds){
        this.mapRef = new google.maps.Map(
            element,
            {
                center: this.$config.startCenter,
                zoom: this.$config.startZoom,
            },
        );

        if (bounds) {
            this.map.fitBounds(bounds, 0);
        }

        this.map.data.setStyle({
            strokeWeight: 1,
            strokeOpacity: 1,
            strokeColor: "#3399FF",
            fillColor: "#3399FF",
            fillOpacity: 0.2,
            editable: false,
            draggable: false,
            clickable: true,
            zIndex: 1,
        });
    }

    get selectedTime() {
        if (this.sliderIndex === null) return "";
        const date = this.mergedTracking?.[this.sliderIndex]?.created_at;
        return timezone.tz(date, this.timeZone).format("YYYY/MM/DD hh:mm A");
    }

    ticketDetails(id) {
        const routeData = this.$router.resolve({
            name: TicketsRoutesEnum.TICKETS_DETAIL, params: { ticketId: id }
        });
        window.open(routeData.href, '_blank');
    }

    private moveMarker(ticket){
        let trackIndex
        let point
        const currentTrackingStep = this.mergedTracking?.[this.sliderIndex]
        if(currentTrackingStep && Array.isArray(currentTrackingStep.ticketId)){
            trackIndex = currentTrackingStep.ticketId.findIndex((tId) => tId === ticket.id)
            if(trackIndex === -1){
                return;
            } else {
                point = {
                    lat: currentTrackingStep.lat[trackIndex],
                    lng: currentTrackingStep.lng[trackIndex],
                }
            }
        } else if(currentTrackingStep && currentTrackingStep.ticketId === ticket.id){
            point = currentTrackingStep
        } else {
            return;
        }

        const {lat, lng} = point;
        ticket.marker.setPosition(new google.maps.LatLng({lat, lng}));
    }

    private _initMarker(){

        this.ticketsHistory.forEach((ticket) => {

            if(!ticket.marker){

                if(!this.wrsColorsMap[ticket.id]){
                    let randomColor
                    const assignColor = () => {
                        randomColor = getDarkColor()
                        for (const [key, value] of Object.entries(this.wrsColorsMap)) {
                            if(value === randomColor){
                                assignColor()
                                return
                            }
                        }
                    }
                    assignColor()
                    this.wrsColorsMap[ticket.id] = randomColor
                    ticket.fillColor = randomColor
                    markerImage.fillColor = randomColor
                }

                const infoWindow = new google.maps.InfoWindow({
                    content: 'Ticket #ID: ' + ticket.id
                });

                ticket.marker = new google.maps.Marker({
                    title: ticket.id.toString(),
                    icon: markerImage
                })

                ticket.marker.addListener("click", () => {
                    infoWindow.open({
                        anchor: ticket.marker,
                        map: this.map
                    });
                });
            }

            this.moveMarker(ticket);

            if(!ticket.selected){
                ticket.marker.setMap(null);
            } else {
                ticket.marker.setMap(this.map);
            }
        })
    }

    private _showOnMap(zone: Zone, center?) {

        const path = zone.shape.map(x =>
            new google.maps.LatLng({lat: x[1], lng: x[0]})
        );

        let shape

        if (zone.zone_type === ZoneTypeEnum.LOAD) {
            shape = (this as any).loadShape
        } else {
            shape = (this as any).dumpShape
        }
        shape.setPath(path);
        shape.setMap(this.map);


        const bounds = new google.maps.LatLngBounds();

        path.forEach((x) => {
            bounds.extend(x)
        });

        new google.maps.Marker({
            position: bounds.getCenter(),
            map: this.map,
            label: {
                text: zone.zone_type === ZoneTypeEnum.LOAD ? 'LOAD ZONE' : 'DUMP ZONE',
                color: "#FFFFFF",
                fontWeight: 'bold'
            },
            icon: {
                url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjyHQt+g8ABFsCIF75EPIAAAAASUVORK5CYII="
            }
        })

        if (center) {
            this.map.fitBounds(bounds);
        }
    }

    async init(){
        await this._loadHistory()
        await this.$nextTick(() => {
            this.setSteps()
        })

        try{
            await this._loadZone('dump', this.shift.zone_dump_id);
        }catch (error) {
            this.zones['dump'] = {'shape' : []};

            console.error("invalid dump zone "+this.shift.zone_dump_id)
        }
        try{
            await this._loadZone('load', this.shift.zone_load_id);
        }catch (error) {
            this.zones['load'] = {'shape' : []};
            console.error("invalid load zone "+this.shift.zone_load_id)
        }


        if (document.body.contains(this.mapElement)) {
            this._buildMap(this.mapElement);
            this._initShape();
            this._drawTracking();
            this._initMarker();
            this._showOnMap(this.zones['load'])
            this._showOnMap(this.zones['dump'], true)
        }
    }

    async mounted(){
        this.shiftLoaded = true
        await this.init()
    }

}
