import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { plainToClass } from 'class-transformer';
import { CRUD }  from './base/crud';
import {CustomShift} from "@/model/api/CustomShift";
import {Shift} from "@/model/api/Shift";

class CustomShiftsService extends CRUD<CustomShift, any> {
    readonly endPoint = `custom-shifts`;

    public async index(params?: any): Promise<PaginatedResponse<CustomShift>> {
        const response:any = await super.index(params);
        response.data = plainToClass(CustomShift, response.data);

        return response;
    }

    public async getShift(id: number): Promise<Shift> {
        const response = await this.post<Shift>(`${this.endPoint}/${id}/get-shift`);
        return plainToClass(Shift, response);
    }

    public async getById(id: number): Promise<CustomShift> {
        const response = await super.getById(id);
        return plainToClass(CustomShift, response);
    }
}

export const customShiftsService = new CustomShiftsService();



