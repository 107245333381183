import {User} from "@/model/api/User";
import {Material} from "@/model/api/Material";
import moment, {Moment} from "moment";
import timezone from "moment-timezone";
import {Shift} from "@/model/api/Shift";
import {Transform} from "class-transformer";

export class CustomShift {
	id           	 		: number;
	shift?           	 	: Shift;

	status			 		: number;
	created_at	 	 		: Date;
	user		 	 		: User
	job_site	 	 		: string
	cost_code	 	 		: string
	shift_reference	 		: string
	poc_name  		 		: string;
	email  		 	 		: string;
	phone  		 	 		: string;
	material  		 		: Material;
	material_state   		: any;
	material_other   		: string;
	load_type  		 		: any;
	load_type_other  		: string;

	is_use_load_w3   		: boolean;
	w3load			 		: string;
	load_driver_note 		: string;
	load_city		 		: string;
	load_address	 		: string;
	load_zip		 		: string;
	load_state		 		: any;

	is_dump_same_load_site  : boolean;
	is_use_dump_w3			: boolean;
	w3dump					: string;
	dump_driver_note		: string;
	dump_city				: string;
	dump_address			: string;
	dump_zip				: string;
	dump_state				: any;

	date					: string;
	zone_name				: string;
	staggered_start_mins	: number;

	contract_name			: string;
	payment_type			: number;
	price_hour				: number;
	price_cycle				: number;
	price_ton				: number;
	trucks_min				: number;
	trucks_max				: number;
	drivers_note			: string;

	@Transform(
		({ value, obj }) => timezone.tz(value, obj.zone_name),
		{ toClassOnly: true }
	)
	@Transform(
		({ value, obj }) => value.tz(obj.zone_name, true)
			.clone().tz("utc")
			.format("YYYY-MM-DDTHH:mm:ss.000000\\Z"),
		{ toPlainOnly: true }
	)
	end_datetime: Moment;

	@Transform(
		({ value, obj }) => timezone.tz(value, obj.zone_name),
		{ toClassOnly: true }
	)
	@Transform(
		({ value, obj }) => value.tz(obj.zone_name, true)
			.clone().tz("utc")
			.format("YYYY-MM-DDTHH:mm:ss.000000\\Z"),
		{ toPlainOnly: true }
	)
	start_datetime: Moment;

	get startDateTime(): string{
		if(this.start_datetime){
			const utcCutoff = moment.utc(this.start_datetime, 'YYYYMMDD HH:mm:ss');
			const displayCutoff = timezone.tz(utcCutoff.clone(), this.zone_name);

			return displayCutoff.format('HH:mm')
		}
	}

	get endDateTime(): string{
		if(this.end_datetime){
			const utcCutoff = moment.utc(this.end_datetime, 'YYYYMMDD HH:mm:ss');
			const displayCutoff = timezone.tz(utcCutoff.clone(), this.zone_name);

			return displayCutoff.format('HH:mm')
		}
	}

}