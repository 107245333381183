import { Options, Vue }                   from "vue-class-component";
import { Prop, Provide }                  from "vue-property-decorator";
import {customShiftsService} from "@services/custom-shifts.service";
import {CustomShift} from "@/model/api/CustomShift";
import {ShiftsRoutesEnum} from "@/modules/shifts/router";
import {AppShiftStatusEnum} from "@/model/enums/AppShiftStatusEnum";
import {UsersRoutesEnum} from "@/modules/users/router";
import {PaymentTypeEnum} from "@/model/enums/PaymentTypeEnum";
import {CustomShiftsRoutesEnum} from "@/modules/custom-shifts/router";
import {plainToClass} from "class-transformer";
import timezone from "moment-timezone";
import moment from "moment";

@Options({
  components: {}
})
export default class CustomShiftPage extends Vue {

  @Prop() 
  @Provide({ reactive: true })
  readonly shiftId!: String;

  shift: CustomShift = new CustomShift();

  get createdAt(){
    const utcCutoff = moment.utc(this.shift.created_at)
    return utcCutoff.clone().tz(this.shift.zone_name);
  }

  onApprove(){
    this.$router.push({
      name: ShiftsRoutesEnum.SHIFTS_DETAIL,
      params: {shiftId: 'new'},
      query: { custom_shift_id: this.shift.id
      }})
  }

  onReject(){
    this.$waitFor(
        async () => {
          const res = await customShiftsService.updatePatch({
            id: this.shift.id,
            status: AppShiftStatusEnum.REJECTED
          } as any);
          this.shift = plainToClass(CustomShift, res)
          this.$successMessage(this.$t(`app_shift.messages.reject_success`));
        }
    )
  }

  async onDelete() {
    const message = this.$t('app_shift.messages.delete_confirm');
    const response = await this.$confirmMessage(message);

    if (response) {
      this.$waitFor(
          async () => {
            await customShiftsService.deleteBySystem(this.shift)
            this.$router.replace({ name: CustomShiftsRoutesEnum.SHIFTS_LIST })
                .then( () =>
                    this.$t('app_shift.messages.delete_success') as string
                )
          }
      );
    }
  }

  get isNew() {
    return this.shiftId === 'new';
  }

  get backRoute() {
    return { name: CustomShiftsRoutesEnum.SHIFTS_LIST }
  }

  get appShiftStatusEnum() {
    return AppShiftStatusEnum
  }

  get shiftsRoutesEnum() {
    return ShiftsRoutesEnum
  }

  get statusBadgeColor() {
    if(this.shift.status === AppShiftStatusEnum.REJECTED){
      return 'warning'
    } else if(this.shift.status === AppShiftStatusEnum.APPROVED){
      return 'success'
    } else {
      return null
    }
  }

  get userRoute() {
    return UsersRoutesEnum.USER_DETAIL;
  }

  get paymentTypeEnum() {
    return PaymentTypeEnum
  }

  get canSeePriceHour() {
    return this.shift.payment_type === PaymentTypeEnum.HOUR
  }
  get canSeePriceCycle() {
    return this.shift.payment_type === PaymentTypeEnum.CYCLE
  }
  get canSeePriceTon() {
    return this.shift.payment_type === PaymentTypeEnum.TON
  }

  private async _loadShift() {
    this.$waitFor(
      async () => {
        this.shift  = await customShiftsService.getById(+this.shiftId);
      }
    )
  }

  async created() {
    if (this.isNew) {
      this.shift = new CustomShift();
    } else {
      await this._loadShift();
    }
  }
}