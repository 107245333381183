import { Options, Vue } from "vue-class-component";
import { FilterMatchMode } from "primevue/api";
import { ShiftsRoutesEnum } from "../../router";
import { SessionStorageEnum } from "@/utils/SessionStorageEnum";

import { shiftsService } from "@services/shifts.service";
import { Shift, ShiftStatusEnum } from "@/model/api/Shift";

import {
  AppTable,
  DuplicateShiftDialog,
  SendMessageDialog,
  ShiftDeleteDialog
} from "@components";

import ShiftsFilters from "./ShiftsFilters.vue";
import { ShiftFilters } from "./ShiftFilters";
import Table from "@components/AppTable/Table";
import moment from "moment";
import { whitelistUsersService } from "@services/whitelist_users.service";
import { authStore } from "@/modules/auth/store";
import {AccessesBySection, UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {classToPlain} from "class-transformer";
import {ProjectsRoutesEnum} from "@/modules/projects/router";
import {ActivitiesRoutesEnum} from "@/modules/activities/router";

@Options({
  components: {
    AppTable,
    DuplicateShiftDialog,
    SendMessageDialog,
    ShiftDeleteDialog,
    ShiftsFilters,
  },

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(ShiftsRoutesEnum.SHIFTS_PREFIX)) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_SHIFTS);
      sessionStorage.removeItem(SessionStorageEnum.SHIFTS_ADVANCED_FILTERS);
    }

    next();
  },

  beforeRouteLeave() {
    if (this.service?.cancelPendingRequests) {
      this.service.cancelPendingRequests();
    }
  },
})
export default class ShiftsPage extends Vue {
  filters: any = null;

  get service() {
    return shiftsService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_SHIFTS
  }

  get newRoute() {
    return {
      name: ShiftsRoutesEnum.SHIFTS_DETAIL,
      params: { shiftId: 'new' }
    }
  }

  get table() {
    return this.$refs.dataTable as Table;
  }

  get canPerformActions() {
    return [
      UserRoleEnum.SUPER_ADMIN,
      UserRoleEnum.CUSTOMER,
      UserRoleEnum.CLIENT
    ].includes(authStore.getters.me.role);
  }

  getRowClass({ trucks_engaged, trucks_min, trucks_required, status }: Shift) {
    if (status === ShiftStatusEnum.DISABLED) {
      return "shift_disabled";
    } else if (status === ShiftStatusEnum.TO_BE_APPROVED) {
      return "shift_tobeapp";
    }

    if(trucks_min == 0)
      trucks_min = 1;
    
    if (trucks_engaged >= trucks_required) {
      return "truck_required";
    } else if ( 
      trucks_engaged <= trucks_required && 
      trucks_engaged >= trucks_min 
    ) {
      return "truck_min";
    }

    return null; 
  }

  goToDetail(shift: Shift) {
    this.$router.push({
      name: ShiftsRoutesEnum.SHIFTS_DETAIL,
      params: { shiftId: shift.id }
    })
  }

  displayDeleteDialog: boolean = false;
  shiftToDelete: Shift = null;

  onDelete(shift: Shift) {
    this.shiftToDelete = shift;
    this.displayDeleteDialog = true;
  }

  showDuplicateDialog: boolean = false;
  selectedShift: Shift = null;
  onClone(shift: Shift) {
    this.selectedShift = shift;

    this.showDuplicateDialog = true;
  }

  onDuplicateCancel() {
    this.showDuplicateDialog = false;
  }

  msgShiftId: number = null;

  updateDates(res){
    ['shift_datetime', 'start_datetime', 'end_datetime', 'expire_at', 'broker_status_at', 'shift_day'].forEach((key) => {
      if(res[key] && res[key] instanceof Date){
        const utcOffset = moment(res[key]).tz(res.zone_name).utcOffset();
        const newDate = moment(res[key].setHours(res[key].getHours() + (-(utcOffset / 60))))
        if(key !== 'shift_day'){
          res[key] = newDate.format('YYYY-MM-DDTHH:mm:ss.000000\\Z')
        } else if(res[key] instanceof Date){
          res[key] = newDate.format('YYYY-MM-DD')
        }
      }
    })
  }

  async onDuplicateConfirm(clone: Shift) {
    try {
      const plainShift = classToPlain(clone);
      if(!plainShift.trucks_required_indipendent){
        plainShift.trucks_required = 0
      }
      this.updateDates(plainShift)
      const cloned = await shiftsService.create(plainShift as any);
      const shiftWhitelist = await whitelistUsersService.getUsers({
        shift_id: this.selectedShift.id
      })

      const promises = []

      shiftWhitelist.forEach(async (broker: any) => {
        promises.push(await whitelistUsersService.addUser({
          shift_id: cloned.id,
          broker
        }))
      })

      Promise.all(promises).then(async () => {
        await this.$successMessage(this.$t('shift.duplicated_message'));

        // this.table.applyFilter();

        this.showDuplicateDialog = false;

        await this.$router.push({
          name: ShiftsRoutesEnum.SHIFTS_DETAIL,
          params: { shiftId: cloned.id }
        })
      }).catch((err) => {
        if(err) this.$errorMessage(this.$t('Operation failed'))
      });


    } catch (error) {
      if(error) this.$errorMessage(this.$t('Operation failed'))
    }

  }

  private deleteShift(shift: Shift) {
    this.$waitFor(
      async () => {
        await shiftsService.deleteBySystem(shift);
        this.$successMessage("Shift successfully deleted")
      },
      "Deleting Shift failed"
    )
  }

  advFilters: ShiftFilters = new ShiftFilters();

  private initFilter() {
    this.filters = {
      // global: { 
      //   value: null, 
      //   matchMode: FilterMatchMode.CONTAINS 
      // },
      shift_day: {
        value: this.advFilters.from,
        matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      },
      id: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      code: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      job_site: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      'customer.business_name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      'project.name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      'activity.name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      status: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
    };
  }

  get imAdmin() {
    return authStore.getters.me.role === UserRoleEnum.SUPER_ADMIN
  }

  get canDuplicate(){
    return AccessesBySection.WRS_EDIT.includes(authStore.getters.me.role)
  }

  goToProjectDetails(shift: Shift){
    if(this.imAdmin){
      const routeData = this.$router.resolve({
        name: ProjectsRoutesEnum.PROJECT_DETAIL,
        params: { projectId: shift.project_id }
      });
      window.open(routeData.href, '_blank');
    }
  }

  goToActivityDetails(shift: Shift){
    if(this.imAdmin){
      const routeData = this.$router.resolve({
        name: ActivitiesRoutesEnum.ACTIVITY_DETAIL,
        params: { activityId: shift.activity_id }
      });
      window.open(routeData.href, '_blank');
    }
  }

  onReset() {
    this.advFilters = new ShiftFilters();
  }

  onFiltersChange() {
    const table = (this.$refs.dataTable as Table);

    if (table?.filters) {
      const filters = table.filters;

      if (this.advFilters.from && this.advFilters.to) {
        const from = this.advFilters.from;
        const to = this.advFilters.to;

        filters.shift_day.value = [from, to];
        filters.shift_day.matchMode = FilterMatchMode.BETWEEN;
      } else {
        filters.shift_day.value = this.advFilters.from;
        filters.shift_day.matchMode = FilterMatchMode.GREATER_THAN_OR_EQUAL_TO;
      }
    }

    table.applyFilter();
  }

  update() {
    console.debug("update");
    this.table.applyFilter();
  }

  created() {
    this.initFilter();
  }

  get statusOptions() {
    return [
      { label: this.$t(`shift.status_${ShiftStatusEnum.ENABLED}`), value: ShiftStatusEnum.ENABLED },
      { label: this.$t(`shift.status_${ShiftStatusEnum.DISABLED}`), value: ShiftStatusEnum.DISABLED },
      { label: this.$t(`shift.status_${ShiftStatusEnum.TO_BE_APPROVED}`), value: ShiftStatusEnum.TO_BE_APPROVED }
    ];
  }

  get isStatusColumnVisible() {
    return [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.CUSTOMER, UserRoleEnum.BROKER].includes(authStore.getters.me.role);
  }

  get isActionsColumnVisible() {
    return [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.CUSTOMER].includes(authStore.getters.me.role);
  }

}
