import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5a22dffc")
const _hoisted_1 = { class: "page" }
const _hoisted_2 = {
  key: 0,
  class: "p-mr-1"
}
const _hoisted_3 = { class: "p-mr-1" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ShiftsFilters = _resolveComponent("ShiftsFilters")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_DateFormatted = _resolveComponent("DateFormatted")!
  const _component_AppTable = _resolveComponent("AppTable")!
  const _component_DuplicateShiftDialog = _resolveComponent("DuplicateShiftDialog")!
  const _component_ShiftDeleteDialog = _resolveComponent("ShiftDeleteDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('shift.list.title')), 1),
      (_ctx.canPerformActions)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: _ctx.newRoute
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Button, {
                icon: "pi pi-plus",
                label: "New"
              })
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ShiftsFilters, {
      filters: _ctx.advFilters,
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => (_ctx.advFilters = $event)),
      onChange: _ctx.onFiltersChange
    }, null, 8, ["filters", "onChange"]),
    _createVNode(_component_AppTable, {
      ref: "dataTable",
      "striped-rows": false,
      filtersSchema: _ctx.filters,
      service: _ctx.service,
      stateKey: _ctx.stateKey,
      sortField: `start_datetime`,
      sortOrder: 1,
      rowClass: _ctx.getRowClass,
      showActions: _ctx.isActionsColumnVisible,
      onRowClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToDetail($event.data))),
      onEdit: _ctx.goToDetail,
      onResetFilters: _ctx.onReset
    }, {
      actions: _withCtx(({data}) => [
        (data.status !== 2 && _ctx.canPerformActions)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _withDirectives(_createVNode(_component_Button, {
                class: "p-button-danger",
                icon: "pi pi-trash",
                onClick: _withModifiers(($event: any) => (_ctx.onDelete(data)), ["stop"])
              }, null, 8, ["onClick"]), [
                [
                  _directive_tooltip,
                  _ctx.$t('delete'),
                  void 0,
                  { bottom: true }
                ]
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_3, [
          (data.status !== 2 && _ctx.canDuplicate)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: "p-button-info",
                icon: "pi pi-clone",
                onClick: _withModifiers(($event: any) => (_ctx.onClone(data)), ["stop"])
              }, null, 8, ["onClick"])), [
                [
                  _directive_tooltip,
                  _ctx.$t('duplicate'),
                  void 0,
                  { bottom: true }
                ]
              ])
            : _createCommentVNode("", true)
        ])
      ]),
      columns: _withCtx(({onApplyFilter}) => [
        _createVNode(_component_Column, {
          header: "Status",
          field: "status",
          showFilterMatchModes: false,
          showFilterOperator: false
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(_ctx.$t(`shift.status_${data.status}`)), 1)
          ]),
          filter: _withCtx(({filterModel}) => [
            _createVNode(_component_Dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              options: _ctx.statusOptions,
              placeholder: "Select status",
              "option-value": "value",
              "option-label": "label"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              label: "apply",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          field: "id",
          showFilterMatchModes: false,
          header: "Shift ID"
        }, {
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Search by shift ID",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.clear'),
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.apply'),
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          field: "code",
          header: "Shift Name",
          showFilterMatchModes: false
        }, {
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Search by shift name",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.clear'),
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.apply'),
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          field: "start_datetime",
          header: "Start Date",
          sortable: true,
          style: {"min-width":"12rem"}
        }, {
          body: _withCtx(({data}) => [
            _createVNode(_component_DateFormatted, {
              date: data.start_datetime,
              format: "YYYY-MM-DD hh:mm A"
            }, null, 8, ["date"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "end_datetime",
          header: "End Date",
          sortable: true,
          style: {"min-width":"12rem"}
        }, {
          body: _withCtx(({data}) => [
            _createVNode(_component_DateFormatted, {
              date: data.end_datetime,
              format: "YYYY-MM-DD hh:mm A"
            }, null, 8, ["date"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "w3load",
          header: "Load Site"
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createElementVNode("strong", null, _toDisplayString(data.zone_load.name), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("i", null, _toDisplayString(data.w3load), 1)
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "w3dump",
          header: "Dump Site"
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createElementVNode("strong", null, _toDisplayString(data.zone_dump.name), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("i", null, _toDisplayString(data.w3dump), 1)
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "activity.name",
          header: "Activity",
          showFilterMatchModes: false
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("a", {
              class: _normalizeClass({'pointer' : _ctx.imAdmin}),
              onClick: ($event: any) => (_ctx.goToActivityDetails(data))
            }, _toDisplayString(data.activity_id) + " " + _toDisplayString(data.activity?.name), 11, _hoisted_4)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Search by Activity name",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.clear'),
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.apply'),
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          field: "project.name",
          header: "Project",
          showFilterMatchModes: false
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("a", {
              class: _normalizeClass({'pointer' : _ctx.imAdmin}),
              onClick: ($event: any) => (_ctx.goToProjectDetails(data))
            }, _toDisplayString(data.project_id) + " " + _toDisplayString(data.project?.name), 11, _hoisted_5)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Search by Project name",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.clear'),
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.apply'),
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          field: "job_site",
          header: "Job Site",
          showFilterMatchModes: false
        }, {
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Search by job site",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.clear'),
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.apply'),
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          header: "minimum #",
          field: "trucks_min"
        }),
        _createVNode(_component_Column, {
          header: "Maximum #",
          field: "trucks_required"
        }),
        _createVNode(_component_Column, {
          header: "# of accepted",
          field: "trucks_engaged"
        }),
        _createVNode(_component_Column, {
          field: "customer.business_name",
          header: "Customer",
          showFilterMatchModes: false
        }, {
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Customer",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.clear'),
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('common.apply'),
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["label", "onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["filtersSchema", "service", "stateKey", "rowClass", "showActions", "onEdit", "onResetFilters"]),
    _createVNode(_component_DuplicateShiftDialog, {
      shift: _ctx.selectedShift,
      visible: _ctx.showDuplicateDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDuplicateDialog = $event)),
      onConfirm: _ctx.onDuplicateConfirm,
      onCancel: _ctx.onDuplicateCancel
    }, null, 8, ["shift", "visible", "onConfirm", "onCancel"]),
    _createVNode(_component_ShiftDeleteDialog, {
      shift: _ctx.shiftToDelete,
      visible: _ctx.displayDeleteDialog,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_ctx.displayDeleteDialog = $event)),
      onDeleted: _ctx.update
    }, null, 8, ["shift", "visible", "onDeleted"])
  ]))
}