import * as api from "@what3words/api";
import {Options, Vue} from "vue-class-component";
import {Inject, Prop, Watch} from "vue-property-decorator";
import {Shift, ShiftStatusEnum} from "@/model/api/Shift";
import {PaymentTypeEnum} from "@/model/enums/PaymentTypeEnum";
import {calcDrivingDistance} from "@/utils/utils";

import {
    TimeZoneDropdown,
    W3Sidebar,
    ZoneAutocomplete,
    ShiftDataAutocomplete,
    ContractAutocomplete,
    MaterialSelect,
    WhitelistUserDialog,
    CompanyAutocomplete, UserFlattenAutocomplete
} from "@components";
import {Contract} from "@/model/api/Contract";
import {User} from "@/model/api/User";
import {whitelistUsersService} from "@services/whitelist_users.service";
import {plainToClass} from "class-transformer";
import {Company} from "@/model/api/Company";
import {CompaniesRoutesEnum} from "@/modules/companies/router";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {authStore} from "@/modules/auth/store";
import {nextTick} from "vue";
import {ProjectsRoutesEnum} from "@/modules/projects/router";
import {ActivitiesRoutesEnum} from "@/modules/activities/router";
import {FilterMatchMode} from "primevue/api";

@Options({
    inheritAttrs: false,
    emits: ['save', 'cloned'],
    components: {
        TimeZoneDropdown,
        ShiftDataAutocomplete,
        W3Sidebar,
        WhitelistUserDialog,
        MaterialSelect,
        ZoneAutocomplete,
        ContractAutocomplete,
        CompanyAutocomplete,
        UserFlattenAutocomplete
    }
})
export default class ShiftData extends Vue {
    @Prop() shift!: Shift;

    @Watch('shift')
    onShiftChange() {
        if (this.$route.params.shiftId === 'new' && this.$route.query.activityId) {
            this.onSameZoneChange();
            // this._calcDistance();
        }
    }

    @Prop() readonly isLoading!: boolean;

    @Inject()
    readonly hasTickets!: boolean;
    @Inject()
    readonly isReadOnly!: boolean;
    @Inject()
    readonly oldStatus!: ShiftStatusEnum;

    initialValue = null;
    hours: any[] = []

    whitelistUsers: User[] = null;

    displayWhiteListDialog: boolean = false;
    inEdit: 'dump' | 'load' = null;
    displayW3Sidebar: boolean = false;
    private activeTicketFields = {
        shift_day: null,
        start_datetime: null,
        end_datetime: null,
        expire_at: null,
        pause_minutes: null,
        zone_name: null,
    }

    get usersQueryOpts(){
        return {
            sortField: 'name',
            sortOrder: 1,
            per_page: 100,
            page: 1
        }
    }

    get userFilters(){
        return {
            "role":{
                "value": UserRoleEnum.FOREMAN,
                "matchMode": FilterMatchMode.EQUALS
            },
            "customer.id": {
                "value": this.shift.customer?.id,
                "matchMode": FilterMatchMode.EQUALS
            }
        }
    }

    get dateFormat() {
        return this.$config.dateFormat;
    }

    get paymentHOUR() {
        return PaymentTypeEnum.HOUR;
    }

    get paymentCYCLE() {
        return PaymentTypeEnum.CYCLE;
    }

    get paymentTON() {
        return PaymentTypeEnum.TON;
    }

    get isCustomerVisible() {
        return [UserRoleEnum.SUPER_ADMIN].includes(authStore.getters.me.role) || [UserRoleEnum.CLIENT].includes(authStore.getters.me.role);
    }

    get isStatusDropdownVisible() {
        return [UserRoleEnum.SUPER_ADMIN].includes(authStore.getters.me.role) && this.oldStatus == ShiftStatusEnum.TO_BE_APPROVED;
    }

    get canGoBroker() {
        return [UserRoleEnum.SUPER_ADMIN].includes(authStore.getters.me.role);
    }
 
    get isWhitelistVisible() {
        return this.canBeManaged();
    }

    get statusOptions() {
        return [
            {label: this.$t(`shift.status_${ShiftStatusEnum.ENABLED}`), value: ShiftStatusEnum.ENABLED},
            {label: this.$t(`shift.status_${ShiftStatusEnum.DISABLED}`), value: ShiftStatusEnum.DISABLED},
            {label: this.$t(`shift.status_${ShiftStatusEnum.TO_BE_APPROVED}`), value: ShiftStatusEnum.TO_BE_APPROVED}
        ];
    }

    get imAdmin() {
        return authStore.getters.me.role === UserRoleEnum.SUPER_ADMIN
    }

    canBeManaged(){
        const me = authStore.getters.me
        if (me.role == UserRoleEnum.SUPER_ADMIN) {
            return true
        }
        if (me.role == UserRoleEnum.CLIENT) {
            if(this.shift.id === undefined || this.shift.contract.client_id == me.client_id) 
            return true;
        }
        if (me.role == UserRoleEnum.CUSTOMER) {
            if(this.shift.id === undefined || this.shift.customer_id == me.customer_id) 
            return true;
        }
        return false;
    }

    goToProjectDetails(){
        if(this.imAdmin){
            this.$router.push({
                name: ProjectsRoutesEnum.PROJECT_DETAIL,
                params: { projectId: this.shift.project_id }
            })
        }
    }

    goToActivityDetails(){
        if(this.imAdmin){
            this.$router.push({
                name: ActivitiesRoutesEnum.ACTIVITY_DETAIL,
                params: { activityId: this.shift.activity_id }
            })
        }
    }

    editW3Load() {
        this.initialValue = this.shift.w3load;

        this.inEdit = 'load';
        this.displayW3Sidebar = true;
    }

    @Watch('displayWhiteListDialog')
    async onWhitelistModalToggle(opened) {
        if (!opened) {
            this.whitelistUsers = [...await whitelistUsersService.getUsers()]
        }
    }

    editW3Dump() {
        this.initialValue = this.shift.w3dump;

        this.inEdit = 'dump';
        this.displayW3Sidebar = true;
    }

    showDetails(broker: User) {
        const routeData = this.$router.resolve({
            name: CompaniesRoutesEnum.BROKER_DETAIL, params: {companyId: broker.id}
        });
        window.open(routeData.href, '_blank');
    }

    onPrivateContractSelect(contract: Contract) {
        if (contract) {
            this.shift.private_contract_id = contract.id;
        } else {
            this.shift.private_contract = null;
            this.shift.private_contract_id = null;
        }
    }

    onContractSelect(contract: Contract) {
        if (contract) {
            this.shift.contract_id = contract.id;
        } else {
            this.shift.contract = null;
            this.shift.contract_id = null;
        }
    }

    onSelectedName($event: any) {
        this.shift.poc_phone = $event.poc_phone
        this.shift.poc_email = $event.poc_email
    }

    async removeUserFromWhitelist(user) {
        this.$waitFor(
            async () => {
                await whitelistUsersService.removeUser({
                    shift_id: this.shift.id,
                    broker_id: user.id
                });
                this.$successMessage("Broker successfully removed from whitelist")
                this.whitelistUsers = [...await whitelistUsersService.getUsers()]
                this.updateTrucksCount()
            },
            this.$t('Operation failed')
        );
    }

    onSelectedJob(shiftSel: Shift) {
        const shift = plainToClass(Shift, shiftSel);
        shift.updateDates()
        shift.note_private = null

        this.$waitFor(
            async () => {
                await whitelistUsersService.getUsers({shift_id: shift.id}).then((res) => {
                    const wls = [...res];
                    wls.forEach((wl: any) => {
                            wl.trucks_engaged = 0;
                            wl.original_trucks_required = wl.trucks_required;
                    })
                    this.whitelistUsers = wls
                });
                for (const key in shift) {
                    if (key !== 'id') {
                        if (
                            (!this.hasTickets || !this.activeTicketFields.hasOwnProperty(key)) &&
                            (key !== 'trucks_required' || shift.trucks_required_indipendent)
                        ) {
                                this.shift[key] = shift[key]
                        }
                    }
                }
                this.updateTrucksCount()
            });
    }

    refreshWhitelist() {
        this.$waitFor(
            async () => {
                const res = await whitelistUsersService.getUsers({shift_id: this.$route.params.shiftId}, true)
                this.whitelistUsers = [...res.map((broker: User) => {
                    return {
                        ...broker,
                        original_trucks_required: broker.trucks_required
                    }
                })]
            }
        )
    }

    public loadWhitelist(shiftId: any = this.$route.params.shiftId) {
        if (shiftId !== 'new') {
            if (this.isWhitelistVisible) {
                return this.$waitFor(
                    async () => {
                        whitelistUsersService.getUsers({shift_id: this.$route.params.shiftId}).then((res) => {
                            this.whitelistUsers = [...res.map((broker: User) => {
                                return {
                                    ...broker,
                                    original_trucks_required: broker.trucks_required
                                }
                            })]
                        });
                    }
                )
            }
        } else {
            this.whitelistUsers = []
        }
    }

    onSameZoneChange($event?: any) {
        if (this.shift.is_dump_same_load_site) {
            this.shift.zone_dump = this.shift.zone_load
            this.shift.zone_dump_id = this.shift.zone_load_id
            this.shift.w3dump = this.shift.w3load;
            nextTick(() => {
                (this.$refs.autocomplete as any).$el.children[0].disabled = true
            })
            this.shift.distance = 0
        } else if($event){
            this.shift.zone_dump = null
            this.shift.zone_dump_id = null
            this.shift.w3dump = null;
            nextTick(() => {
                (this.$refs.autocomplete as any).$el.children[0].disabled = false
            })
        }
    }


    private async onRemove(user: User) {
        this.$waitFor(
            async () => {
                await whitelistUsersService.removeUser({
                    shift_id: this.shift.id,
                    broker_id: user.id
                });
                this.$successMessage("User successfully removed from whitelist")
            },
            this.$t('Operation failed')
        );
    }

    private async editWhitelistUser(broker: User) {
        this.$waitFor(
            async () => {
                await whitelistUsersService.addUser({
                    shift_id: this.shift.id,
                    broker
                });
                (broker as any).original_trucks_required = broker.trucks_required
                this.$successMessage("Broker successfully updated")
                this.updateTrucksCount()
            },
            this.$t('Operation failed'),
        );
    }

    private updateTrucksCount() {
        let sumTruckRequired = 0
        this.whitelistUsers.forEach((user: User) => {
            sumTruckRequired += user.trucks_required
        })
        if(!this.shift.trucks_required_indipendent){
            this.shift.trucks_required = sumTruckRequired
        }
    }

    private async onAdd(broker: User) {
        if(!this.shift.trucks_required_indipendent){
            broker.trucks_required = 0;
        }
        (broker as any).original_trucks_required = 0
        this.$waitFor(
            async () => {
                await whitelistUsersService.addUser({
                    shift_id: this.shift.id,
                    broker
                })
                this.$successMessage("Broker successfully added to whitelist")
                this.updateTrucksCount()
            },
            this.$t('Operation failed')
        );
    }

    mounted() {
        for (let i = 1; i < new Array(24).length; i++) {
            let value
            if (i < 10) {
                value = `0${i}:00`
            } else {
                value = `${i}:00`
            }
            this.hours.push(value)
        }

        if ((this.shift.id || this.shift.custom_shift_id) && this.shift.is_dump_same_load_site) {
            (this.$refs.autocomplete as any).$el.children[0].disabled = true
        }
        this.loadWhitelist(this.shift.id)
    }

    onZoneLoadSelect($event) {
        this.shift.zone_load = $event
        this.shift.zone_load_id = this.shift.zone_load?.id;
        this.shift.w3load = this.shift.zone_load.w3;
        this.shift.load_lat = this.shift.zone_load.lat;
        this.shift.load_lng = this.shift.zone_load.lng;
        if (this.shift.is_dump_same_load_site) {
            this.shift.zone_dump = $event
            this.shift.zone_dump_id = this.shift.zone_load?.id;
            this.shift.w3dump = this.shift.zone_load.w3;
            this.shift.dump_lat = this.shift.zone_load.lat;
            this.shift.dump_lng = this.shift.zone_load.lng;
        }
        this._calcDistance();
    }

    onZoneDumpSelect() {
        this.shift.zone_dump_id = this.shift.zone_dump?.id;
        this.shift.w3dump = this.shift.zone_dump.w3;
        this.shift.dump_lat = this.shift.zone_dump.lat;
        this.shift.dump_lng = this.shift.zone_dump.lng;

        this._calcDistance();
    }

    onCustomerSelected(company?: Company) {

        this.shift.foreman_id = null
        this.shift.foreman = null

        this.shift.contract_id = null
        this.shift.contract = null

        if (company?.id != this.shift.customer_id) {
            this.shift.zone_load = null
            this.shift.zone_load_id = null
            this.shift.zone_dump = null
            this.shift.zone_dump_id = null
        }

        if (company) {
            this.shift.customer = company
            this.shift.customer_id = company.id
        } else {
            this.shift.customer = null
            this.shift.customer_id = null

        }
    }

    changeShiftCode($event) {
        if ($event.target.value === '' && !!this.shift.job_site) {
            this.shift.code = `${this.shift.job_site} 001`
        }
    }

    onSaveW3(w3Result: api.LocationJsonResponse) {
        if (this.inEdit === 'load') {
            this.shift.w3load = w3Result.words;
            this.shift.load_lat = w3Result.coordinates.lat;
            this.shift.load_lng = w3Result.coordinates.lng;
            if (this.shift.is_dump_same_load_site) {
                this.inEdit = 'dump'
                this.onSaveW3(w3Result)
            }
        } else {
            this.shift.w3dump = w3Result.words;
            this.shift.dump_lat = w3Result.coordinates.lat;
            this.shift.dump_lng = w3Result.coordinates.lng;
        }
    }

    private async _calcDistance() {
        if (this.shift.zone_dump && this.shift.zone_load) {
            const x = {
                lat: this.shift.zone_load.lat,
                lng: this.shift.zone_load.lng,
            }

            const y = {
                lat: this.shift.zone_dump.lat,
                lng: this.shift.zone_dump.lng,
            }

            this.shift.distance = await calcDrivingDistance(x, y);
        }

        return;
    }

    get isOver24() {

        if (!this.shift.endDateTime || !this.shift.startDateTime)
            return false

        return ((this.shift.endDateTime.getHours() * 60) + this.shift.endDateTime.getMinutes()) < ((this.shift.startDateTime.getHours() * 60) + this.shift.startDateTime.getMinutes())
    }

  get isBroker() {
    return authStore.getters.me.role == UserRoleEnum.BROKER;
  }

  get isCustomer() {
    return authStore.getters.me.role == UserRoleEnum.CUSTOMER;
  }

  get isClient() {
    return authStore.getters.me.role == UserRoleEnum.CLIENT;
  }

  get isSuperAdmin() {
    return authStore.getters.me.role == UserRoleEnum.SUPER_ADMIN;
  }
}
