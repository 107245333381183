import { Type } from "class-transformer";
import moment from "moment";

export class ShiftFilters {
    @Type(() => Date)
    from_to: Date[];

    get from() {
        const from = this.from_to?.[0];
        if (from) {
            //from = new Date(new Date(from).setHours(0, 0, 0, 0));
            return moment(from).format("YYYY-MM-DD");
        }

        return null;
    }

    get to() {
        const to = this.from_to?.[1];
        if (to) {
            //to = new Date(new Date(to).setHours(23, 59, 59, 999)); 
            return moment(to).format("YYYY-MM-DD");
        }

        return null;
    }


    constructor() {
        const from = new Date();
        from.setHours(0, 0, 0, 0);
        this.from_to = [from];
    }
}
