import {FilterMatchMode} from "primevue/api";
import {Options, Vue} from "vue-class-component";
import {Inject, Prop} from "vue-property-decorator";

import {ShiftsRoutesEnum} from "@/modules/shifts/router";
import {SessionStorageEnum} from "@/utils/SessionStorageEnum";
import {ticketsService} from "@services/tickets.service";
import {Ticket} from "@/model/api/Ticket";

import {AddTicketDialog, AppTable, PaymentStatus, TicketFinishStatus, TicketStatus} from "@components";

import Table from "@components/AppTable/Table";

import TicketDisputeResolverDialog from "@/components/TicketDisputeResolverDialog/TicketDisputeResolverDialog.vue";

import {activeStatuses, finishStatuses, inactiveStatuses, TicketStatusEnum} from "@/model/enums/TicketStatusEnum";
import moment from "moment";
import {TicketsRoutesEnum} from "@/modules/tickets/router";
import {fileDownload} from "@/utils/utils";
import {PaymentTypeEnum} from "@/model/enums/PaymentTypeEnum";
import {Shift} from "@/model/api/Shift";
import {whitelistUsersService} from "@services/whitelist_users.service";
import {authStore} from "@/modules/auth/store";
import {AccessesBySection, UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {PaymentStatusEnum} from "@/model/enums/PaymentStatusEnum";

@Options({
  inheritAttrs: false,

  components: {
    AddTicketDialog,
    AppTable,
    PaymentStatus,
    TicketStatus,
    TicketFinishStatus,
    TicketDisputeResolverDialog
  },
  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(ShiftsRoutesEnum.SHIFTS_DETAIL)) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_SHIFT_TICKETS);
    }

    const tableState = JSON.parse(sessionStorage.getItem(SessionStorageEnum.TABLE_STATE_SHIFT_TICKETS));
    if (tableState?.filters?.shift_id?.value !== to.params.shiftId) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_SHIFT_TICKETS);
    }

    next();
  },

  beforeRouteLeave() {
    sessionStorage.removeItem('ticketsOnlyActive');

    if (this.service?.cancelPendingRequests) {
      this.service.cancelPendingRequests();
    }
  },
})
export default class ShiftTickets extends Vue {
  @Inject()
  readonly shiftId!: string;

  @Prop() readonly shift!: Shift;

  filters: any = null;

  brokers: any[] = []

  exporting: boolean = false;

  onlyActive: boolean = true;

  selectedTicket: Ticket = null;

  displayAddTicketDialog: boolean = false

  displayResolverDialog: boolean = false;

  get isVisible(){
    return AccessesBySection.WRS_EDIT.includes(authStore.getters.me.role)
  }

  get exports() {
    return [
      {
        label: 'Excel',
        command: () => {
          this.onExport('excel')
        }
      },
      {
        label: 'PDF',
        command: () => {
          this.onExport('pdf')
        }
      }
    ]
  }

  get canSeeTotalTon() {
    return this.shift.payment_type === PaymentTypeEnum.TON || this.shift.is_ton_requested == true;
  }

  get service() {
    return ticketsService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_SHIFT_TICKETS
  }

  get selectedTicketId() {
    return this.selectedTicket?.id;
  }

  get isBroker() {
    return authStore.getters.me.role == UserRoleEnum.BROKER;
  }

  get statusOptions() {
    const statuses = [...activeStatuses];

    if (!this.onlyActive) {
      statuses.push(...inactiveStatuses);
    }

    return statuses.map(s => ({
      label: this.$t(`ticket.statuses.${TicketStatusEnum[s]}`),
      value: s
    }))
  }

  get finishStatusOptions() {
    return finishStatuses.map(s => ({
      label: this.$t(`ticket.finish_status.${s}`),
      value: s
    }))
  }

  getDuration(ticket: Ticket) {
    const startMoment = moment(ticket.datetime_start);
    if (!startMoment.isValid()) {
      return null
    }
    let d;

    if (!ticket.datetime_end.isValid()) {
      d = moment.duration(moment().diff(startMoment));
    } else {
      const endMoment = moment(ticket.datetime_end);
      d = moment.duration(endMoment.diff(startMoment));
    }

    const h = Math.floor(d.asHours());
    const m = Math.floor(d.minutes());
    const s = Math.floor(d.seconds());

    return [h, m, s].map(x => x.toString().padStart(2, '0')).join(':');
  }

  onAdd($event) {
    this.$confirmMessage("Are you sure you want to create an empty ticket with closed status for this user?")
        .then(r => {
          if (r) {
            this.$waitFor(
                async () => {
                  const res: any = await ticketsService.createAndClose($event, this.shiftId);
                  await this.$router.push({
                    name: TicketsRoutesEnum.TICKETS_DETAIL,
                    params: {ticketId: res.ticket_id}
                  })
                  this.displayAddTicketDialog = false
                  this.$successMessage("Ticket successfully created")
                },
                this.$t('Operation failed')
            )
          }
        })
  }

  canDelete(t: Ticket) {
    return [TicketStatusEnum.ACCEPTED_BY_USER, TicketStatusEnum.DELETED_BY_USER, TicketStatusEnum.DELETED_BY_SYSTEM].includes(t.status);
  }

  rowClass(ticket: Ticket) {
    return ticket.status == TicketStatusEnum.DELETED_BY_USER || ticket.status == TicketStatusEnum.DELETED_BY_SYSTEM ? 'row-deleted-by-user' : null;
  }

  trashLabel(ticket) {
    return ticket.status === TicketStatusEnum.ACCEPTED_BY_USER ? this.$t('ticket.actions.decline') : this.$t('ticket.actions.delete')
  }

  isDispute(t: Ticket) {
    return t?.status === TicketStatusEnum.DISPUTE;
  }

  async onExport(type: string){
      if(type === 'excel'){
        this.onExportInmpl(type, false);
      }else{
        this.$confirmMessage("Do you want to include images?").then(r => {
        this.onExportInmpl(type, r);
      })
    }

  }

  async onExportInmpl(type: string, withImages: boolean) {
    try {
      const filters = (this.$refs.table as Table).filters;

      this.exporting = true

      const response = await ticketsService.exportFile(filters, type, withImages);

      this.exporting = false

      const fileName = `TicketList_Export_${moment().format('YYYY_MM_DD')}`;

      fileDownload(
          fileName,
          response,
          type === 'excel' ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "application/pdf"
      )
    } catch (error) {
      console.error(error);
      this.exporting = false
      this.$errorMessage("Export failed");
    }
  }

  onResolve(t: Ticket) {
    this.selectedTicket = t;
    this.displayResolverDialog = true;
  }

  onResolved() {
    this.displayResolverDialog = false;
    this.selectedTicket = null;

    this.$successMessage("Ticket dispute resolved");
  }

  onResolveCancel() {
    this.displayResolverDialog = false;
    this.selectedTicket = null;
  }

  onUpdateOnlyActive(val: boolean) {

    sessionStorage.setItem('ticketsOnlyActive', val.toString());

    const table = (this.$refs.table as Table);

    table.filters.status = {
      value: val ? activeStatuses : null,
      matchMode: FilterMatchMode.IN
    }

    table.onApplyFilter();
  }

  onEdit(ticket: Ticket) {
    if(this.isVisible){
      this.$router.push({
        name: TicketsRoutesEnum.TICKETS_DETAIL,
        params: {
          ticketId: ticket.id
        }
      });
    }
  }

  onDeleted() {
    this.selectedTicket = null;
    this.refreshTable();
    this.$successMessage("Ticket deleted successfully");
  }

  update() {
    this.refreshTable();
  }

  onDelete(ticket: Ticket) {

    if (ticket.status === TicketStatusEnum.ACCEPTED_BY_USER) {
      this.$confirmMessage(this.$t('ticket.messages.decline_confirm')).then(r => {
        if (r) {
          this.deleteTicket(ticket);
        }
      })
    } else {
      this.$confirmMessage(this.$t('ticket.messages.delete_confirm')).then(r => {
        if (r) {
          this.deleteTicket(ticket, true);
        }
      })
    }


  }

  private deleteTicket(ticket: Ticket, standard?) {
    this.$waitFor(
        async () => {
          if (!standard) {
            await ticketsService.deleteBySystem(ticket);
          } else {
            await ticketsService.deleteStandard(ticket);
          }
          this.refreshTable();
          this.$successMessage("Ticket successfully deleted")
        },
        "Deleting Ticket failed"
    )
  }

  private refreshTable() {
    const table = (this.$refs.table as Table);
    table.applyFilter();
  }

  get paymentStatusOptions() {
    return [
      { label: this.$t(`ticket.payment_statuses.BLANK`), value: '' },
      { label: this.$t(`ticket.payment_statuses.PAID`), value: PaymentStatusEnum.PAID },
      { label: this.$t(`ticket.payment_statuses.PENDING`), value: PaymentStatusEnum.PENDING },
      { label: this.$t('ticket.payment_statuses.APPROVED'), value: PaymentStatusEnum.APPROVED },
      { label: this.$t(`ticket.payment_statuses.AUTHORIZED`), value: PaymentStatusEnum.AUTHORIZED },
    ]
  }

  private initFilter() {
    this.filters = {
      shift_id: {
        value: this.shiftId,
        matchMode: FilterMatchMode.EQUALS
      },
      payment_status: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      status: {
        value: [...activeStatuses],
        matchMode: FilterMatchMode.IN
      },
      finish_status: {
        value: null,
        matchMode: FilterMatchMode.IN
      },
      datetime_assigned: {
        value: null,
        matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      },
      datetime_end: {
        value: null,
        matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
      }
    };
  }

  get isNew() {
    return this.$route.params.shiftId === 'new'
  }

  activated() {
    if (!this.isNew) {
      whitelistUsersService.getUsers({shift_id: this.shiftId}).then((res) => {
        this.brokers = [...res]
      });
    }
  }

  created() {

    const fromStorage = sessionStorage.getItem('ticketsOnlyActive')

    if (fromStorage) {
      this.onlyActive = JSON.parse(
          sessionStorage.getItem('ticketsOnlyActive')
      );
    }

    this.initFilter();
  }
}
